@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
}
@layer utilities {
  .placeholder-bold::placeholder{
    font-weight: 400 !important;
    font-size: 1rem/* 16px */!important;
    line-height: 1.5rem/* 24px */!important;
  }
  .table-spacing {
    border-spacing: 0px 26px !important;
  }
  .shadow-dark {
    box-shadow: 0px 8px 24px rgba(112, 114 ,176,0.21) !important;
  }
}
input[type="checkbox"].selectCheck:checked{
  @apply bg-primary-200 border-none;
}
input[type="checkbox"].selectCheck:checked + span.checkbox-container{
  @apply block;
}
iframe {
  position: unset !important;
  z-index: -4 !important;
}
