#container .ant-select .ant-select-selector {
  border-radius: 20px;
}
.ant-select {
  padding: 0px !important;
  border: 2px solid #c6ccd2;
  margin: 10px;
  border-radius: 10px;
}
.ant-tag {
  padding: 4px !important;
  border: 0px solid #c6ccd2;
  margin: 10px;
  border-radius: 10px;
}
